import React from 'react';

const Thankyou = () => {
  return (
    <section id="thankyou" className="thankyou">
      <div className="container">
        <h2>Thank You!</h2>
        <p>Thank you for contacting us. We have received your message and will get back to you shortly.</p>
      </div>
    </section>
  );
}

export default Thankyou;
