import React from 'react';

const FAQSection = () => {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="faq-list">
          <ul>
            <li data-aos="fade-up" data-aos-delay="100">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">How are residents billed for our services? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                <p>We offer two billing options: Directly to residents: Residents can register with Swify IT Help directly, and we will invoice them individually. Through the retirement community: We invoice the retirement community, which can then charge the residents. Some communities include our tech assistance in an all-inclusive living package.</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Do you conduct background checks on your tech specialists? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                <p>Yes, we thoroughly vet all our tech specialists. This includes obtaining references from previous employers and conducting comprehensive background checks.</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Can our residents easily communicate with your tech specialists? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                <p>Absolutely. Our tech specialists are not only highly skilled in technology but also proficient in US English, ensuring clear and effective communication.</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="400">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Do you offer in-person assistance? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                <p>Yes, we can provide in-person assistance if required. Many of our clients opt for this service, and we are more than willing to evaluate the need and offer a tech concierge for your community.</p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="500">
              <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Which devices and technologies are covered by your services? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
              <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                <p>We provide assistance for a wide range of technologies, including PCs, laptops, tablets, Macs, smartphones, printers, scanners, email, Wi-Fi, and more. However, we do not cover cable TV and medical devices.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
