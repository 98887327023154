import React from 'react';

const AboutSection = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row content">
          <div className="col-lg-8">
            <h3>With Swify IT Help as your IT partner, you will get:</h3>
            <ul>
              <li><i className="ri-check-double-line"></i> Proactive Problem Prevention: Swify IT Help actively monitors and addresses IT concerns before they escalate, ensuring seamless operations and minimal downtime for your business.</li>
              <li><i className="ri-check-double-line"></i> Expertise on Demand: Our seasoned IT professionals are readily available, guaranteeing rapid responses and effective solutions to any tech challenges you face.</li>
              <li><i className="ri-check-double-line"></i> Tailored IT Strategies: We understand that every business is unique. Swify IT Help crafts customized IT roadmaps that align perfectly with your goals and growth projections.</li>
              <li><i className="ri-check-double-line"></i> Cutting-Edge Solutions: Stay ahead of the curve with the latest technological advancements. We provide our partners with top-tier tools and innovations for sustained competitive advantage.</li>
            </ul>
          </div>
          <div className="col-lg-4 pt-4 pt-lg-0 d-flex align-items-stretch forms">
            <form action="" id="contactForm" className="php-email-form">
              <div className="row">
                <h4>Get Unlimited IT Assist</h4>
                <p>for as little as $99/month</p>
              </div>
              <div className="inputBox form-group">
                <input type="text" id="name" placeholder="Your name...." />
              </div>
              <div className="inputBox form-group">
                <input type="email" id="emailid" placeholder="Your Email....." />
              </div>
              <div className="inputBox form-group">
                <textarea id="phone" cols="0" rows="1" placeholder="Your Phone..."></textarea>
              </div>
              <div className="inputBox">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
