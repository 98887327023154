import React from 'react';
import ContactForm from '../components/ContactForm'; // Adjust the path as needed

const AboutSection = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="row content">
          <div className="col-lg-8">
            <h3>With Tech Care Support as your IT partner, you will get:</h3>
            <ul>
              <li><i className="ri-check-double-line"></i> Proactive Problem Prevention: Comp Care Takers Support actively monitors and addresses IT concerns before they escalate, ensuring seamless operations and minimal downtime for your business.</li>
              <li><i className="ri-check-double-line"></i> Expertise on Demand: Our seasoned IT professionals are readily available, guaranteeing rapid responses and effective solutions to any tech challenges you face.</li>
              <li><i className="ri-check-double-line"></i> Tailored IT Strategies: We understand that every business is unique. Comp Care Takers Support crafts customized IT roadmaps that align perfectly with your goals and growth projections.</li>
              <li><i className="ri-check-double-line"></i> Cutting-Edge Solutions: Stay ahead of the curve with the latest technological advancements. We provide our partners with top-tier tools and innovations for sustained competitive advantage.</li>
            </ul>
          </div>
          <div className="col-lg-4 pt-4 pt-lg-0 d-flex align-items-stretch forms">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
