import React from 'react'
import Clients1 from '../img/clients/trust-pilot.png'
import Clients2 from '../img/clients/mcafee.png'
import Clients3 from '../img/clients/bbb-logo.png'
import Clients4 from '../img/clients/ms-partner.jpg'
import Clients5 from '../img/clients/ICON-NETWORK.svg'
import Clients6 from '../img/clients/500startups.png'

const Contact = () => {
  return (
    <>
    <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
      <h1>Contact Us</h1>
      <h1></h1>
    </div>
    <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
      <img src="" class="img-fluid animated" alt="" />
    </div>
  </div>
</div>

</section>

<section id="cliens" class="cliens section-bg">
      <div class="container">
        <div class="section-title">
         
          
        </div>
        <div class="row" data-aos="zoom-in">

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients1} class="img-fluid" alt="" />
            
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients2} class="img-fluid" alt="" />
            
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients3} class="img-fluid" alt="" />
            
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients4} class="img-fluid" alt="" />
            
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients5} class="img-fluid" alt="" />
           
          </div>

          <div class="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
            <img src={Clients6} class="img-fluid" alt="" />
           
          </div>

        </div>

      </div>
    </section>


    <section id="about" class="about">
      <div class="container" data-aos="fade-up">

        <div class="row content">
            <div class="forms">
              <form action="" id="contactForm" class="php-email-form">
                <div class="row">
                  <h4>Get Unlimited IT Support</h4>
                      <p>for as little as $99/month</p>
                </div>
                <div class="alert">Your message sent</div>
    
                <div class="inputBox form-group">
                    <input type="text" id="name" placeholder="Your name...." />
                </div>
    
                <div class="inputBox form-group">
                    <input type="email" id="emailid" placeholder="Your Email....." />
                </div>
    
                <div class="inputBox form-group">
                    <textarea id="phone" cols="0" rows="1" placeholder="Your Phone..."></textarea>
                </div>
    
                <div class="inputBox">
                    <button type="submit">Submit</button>
                </div>
            </form>
              </div>

      </div>
      </div>
    </section>
    
    </>
  )
}

export default Contact